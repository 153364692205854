let routes = [
  {
    path: "/onboarding",
    meta: {
      requiresAuth: true
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    beforeEnter(to, from, next) {
      next();
    },
    children: [
      {
        path: "selezione-servizio",
        name: "DeliveryServiceView",
        component: () => import("@/views/delivery/DeliveryServiceView.vue"),
        meta: {
          slug: "delivery-service-selection",
          breadCrumb: "Selezione servizio",
          requiresAuth: true,
          site: "ecommerce"
        }
        // meta: { slug: "delivery-service-selection" }
      },
      {
        path: "selezione-indirizzo",
        name: "AddressSelectorView",
        props: route => ({
          deliveryServiceId: route.query.deliveryServiceId
        }),
        component: () => import("@/views/delivery/AddressSelectorView.vue"),
        meta: {
          // slug: `addressSelector.deliveryService.${this.route.query.deliveryServiceId}.slug`,
          breadCrumb: "Selezione indirizzo",
          requiresAuth: true,
          site: "ecommerce"
        }
        // meta: { slug: "delivery-address-selection" }
      },
      {
        path: "selezione-fascia-oraria",
        name: "TimeslotSelectorView",
        props: route => ({
          deliveryServiceId: route.query.deliveryServiceId
        }),
        component: () => import("@/views/delivery/TimeslotSelectorView.vue"),
        meta: {
          slug: "timeslot",
          breadCrumb: "Selezione fascia oraria",
          requiresAuth: true,
          site: "ecommerce"
        }
        // meta: { slug: "timeslot-selector-selection" }
      },
      {
        path: "work-and-shop",
        name: "WorkAndShop",
        props: route => ({
          deliveryServiceId: route.query.deliveryServiceId
        }),
        component: () => import("@/views/delivery/WorkAndShop.vue"),
        meta: {
          breadCrumb: "Work and shop",
          slug: "work-and-shop",
          requiresAuth: true,
          site: "ecommerce"
        }
      }
    ]
  }
];

export default routes;
