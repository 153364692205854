let routes = [
  {
    path: "/notification/notifications-history",
    name: "NotificationsHistory",
    component: () => import("@/views/notification/NotificationsHistory.vue"),
    meta: {
      site: "ecommerce",
      slug: "notification/notifications-history"
    }
  }
];

export default routes;
