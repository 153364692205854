<template>
  <v-app
    v-if="i18nLoaded"
    :class="[
      getKeyboardOpen ? classes + ' keyboard-open' : classes,
      { 'platform-ios': platformIos },
      site ? site : ''
    ]"
  >
    <!-- <NewsBannerSlider v-if="isAuthenticated" /> -->
    <Navbar
      :key="key"
      :applicationConfig="applicationConfig"
      :siteLinks="siteLinks"
    />
    <v-navigation-drawer
      v-model="drawerLeft"
      width="300"
      touchless
      left
      :temporary="$vuetify.breakpoint.lgOnly"
      app
      clipped
      class="categories"
    >
      <CategoryTree v-show="site == 'ecommerce'" />
      <SiteNavigationDrawer v-show="site == 'site'" :links="footerLinks" />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawerRight"
      disable-resize-watcher
      touchless
      temporary
      clipped
      right
      app
      width="600"
      id="cart-right-drawer"
      class="cart-drawer white"
    >
      <div class="cart-info" v-if="drawerRight">
        <CartItemList class="items-list" />
        <CartInfoList :isCart="true" class="infos py-3 pb-sm-0" />
        <CartSummary
          class="summary py-0"
          :class="{ 'cart-item-list-no-checkout-osx': iosMobile }"
        />
      </div>
    </v-navigation-drawer>

    <!-- <v-navigation-drawer
      class="links-list white"
      v-if="cordovaPlatform"
      v-model="drawerLinks"
      touchless
      right
      clipped
      app
      width="auto"
      @close="drawerLinks = false"
    >
      <MenuLinksList :links="footerLinks" @close="drawerLinks = false" />
    </v-navigation-drawer> -->
    <v-main>
      <ebsn-style
        :target="applicationConfig"
        path="category_advanced.CSS_STYLE"
      />
      <div class="content-wrap">
        <v-skeleton-loader
          type="actions, image, article,image"
          v-if="loading"
          :loading="loading"
        ></v-skeleton-loader>
        <router-view v-else></router-view>
      </div>
      <category-block
        v-if="applicationConfig"
        :target="applicationConfig"
        position="tutorial"
        class="category-block category-block-tutorial"
      />

      <Footer :links="footerLinks" :applicationConfig="applicationConfig" />
      <div class="privacy-links" v-if="isCordova">
        <PrivacyLinks></PrivacyLinks>
        <div>
          &nbsp;|&nbsp;
          <router-link to="/page/condizioni-dacquisto">{{
            $t("footer.links.buyConditions")
          }}</router-link>
        </div>
      </div>
    </v-main>

    <ScrollTop />
    <SsoUserCard />
    <!-- <GiftCertificateWindow /> -->
  </v-app>
</template>
<style lang="scss">
.cart-info {
  .infos {
    background-color: var(--v-green-lighten2) !important;
    height: #{$cart-info-height};
  }
  .summary {
    background: var(--v-grey-lighten2);
    position: fixed;
    bottom: 0;
    width: 100%;
    height: #{$summary-height};
    border-top-color: var(--v-green-lighten2);
  }
  .items-list {
    $background: $white;
  }
  .cart-item-list {
    height: calc(100vh - #{$cart-info-height} - #{$summary-height});
    overflow-y: hidden;
    overscroll-behavior: contain;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: auto;
    .items-list {
      background: transparent;
    }
    .infos {
      height: auto;
      box-shadow: 0px 3px 6px #00000029 !important;
    }
    .summary {
      box-shadow: 0px -3px 6px #00000029 !important;
    }
    .cart-item-list-no-checkout-osx {
      padding-bottom: $summary-height-osx !important;
    }
  }
}
</style>
<script>
import CartSummary from "@/components/cart/CartSummary.vue";
import CartInfoList from "@/components/cart/CartInfoList.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import CategoryTree from "@/components/navigation/CategoryTree.vue";
import SiteNavigationDrawer from "@/components/navigation/SiteNavigationDrawer.vue";
import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";
// import NewsBannerSlider from "@/components/news/NewsBannerSlider.vue";
import SsoUserCard from "@/components/profile/SsoUserCard.vue";
import Navbar from "@/components/navigation/Navbar.vue";
// import NavbarShop from "@/components/navigation/NavbarShop.vue";
// import GiftCertificateWindow from "@/components/gift/GiftCertificateWindow.vue";
import PrivacyLinks from "@/components/navigation/PrivacyLinks.vue";

import login from "~/mixins/login";
import version from "~/mixins/version";

import CmService from "~/service/cmService";
import UserService from "~/service/userService";
import CategoryService from "~/service/categoryService";

import { mapActions, mapGetters, mapState } from "vuex";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import { setLocale } from "@/plugins/i18n";

// import FingerprintJS from "@fingerprintjs/fingerprintjs";
import ScrollTop from "./components/ScrollTop.vue";
import analyticsService from "./commons/service/analyticsService";

export default {
  name: "App",
  components: {
    CategoryTree,
    SiteNavigationDrawer,
    CartInfoList,
    CartSummary,
    CartItemList,
    // NewsBannerSlider,
    Footer: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/navigation/BottomNavBar.vue")
        : import("@/components/navigation/Footer.vue"),
    Navbar,
    // NavbarShop,
    // Navbar: () =>
    //   process.env.CORDOVA_PLATFORM
    //     ? import("@/components/navigation/NavbarMobile.vue")
    //     : import("@/components/navigation/Navbar.vue"),
    SsoUserCard,
    ScrollTop,
    PrivacyLinks
    // ,
    // GiftCertificateWindow
  },
  mixins: [login, version],
  data() {
    return {
      load404: false,
      initialHref: window.location.href,
      loading: true,
      footerLinks: [],
      siteLinks: [],
      applicationConfig: null,
      i18nLoaded: false,
      key: 1,
      classes: process.env.CORDOVA_PLATFORM ? "is-cordova" : "",
      platformIos: false,
      keyboardOpen: false,
      iosMobile: false,
      attValue: false
    };
  },
  metaInfo: {
    title: "Basko",
    titleTemplate: "%s - Basko",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content:
          "Basko Spesa Online è il nuovissimo servizio di Basko che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      {
        name: "keywords",
        content: "Basko Spesa Online supermercato"
      },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "author", content: "Digitelematica s.r.l." },
      // Open Graph Metas
      {
        property: "og:locale",
        content: "it_IT"
      },
      {
        property: "og:title",
        content: "%s - Basko"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:url",
        content: location.href
      },
      {
        property: "og:image",
        content:
          window.location.host + global.config.socialLogo ||
          "/logo/social-logo.png"
      },
      {
        property: "og:image:alt",
        content:
          "Basko Spesa Online è il nuovissimo servizio di Basko che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      {
        property: "og:site_name",
        content: "Basko"
      },
      // Twitter Metas
      {
        name: "twitter:card",
        content: "summary_large_image"
      },
      {
        name: "twitter:title",
        content: "%s - Basko"
      },
      {
        name: "twitter:image",
        content:
          window.location.host + global.config.socialLogo ||
          "/logo/social-logo.png"
      },
      {
        name: "twitter:url",
        content: location.href
      }
    ]
  },
  computed: {
    ...mapGetters({
      needRestart: "needRestart",
      isAuthenticated: "cart/isAuthenticated",
      site: "custom/getSite"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    drawerRight: {
      get() {
        return this.$store.state.app.drawerRight;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerRight", value);
      }
    },
    getUserId() {
      return this.$store.state.cart.cart.user
        ? this.$store.state.cart.cart.user.userId
        : undefined;
    },
    getKeyboardOpen: function() {
      return this.keyboardOpen;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  },
  methods: {
    ...mapActions({
      updateAuth: "cart/updateAuth",
      resetFilters: "category/resetFilters",
      updateLastStartTime: "app/updateLastStartTime",
      updateFingerprint: "app/updateFingerprint",
      getCart: "cart/getCart"
    }),
    async fetchSiteLinks() {
      let res = await CmService.getMenuByLocation("primary?v=0");

      this.siteLinks = res;
      // data => (this.footerLinks = data);
    },
    async checkGotPointsFromBasko(attempt) {
      try {
        if (attempt <= 3) {
          let _this = this;
          setTimeout(async function() {
            await _this.getCart();
            if (get(_this.cart, "user.userPointsCounters[0].value") == -1) {
              _this.checkGotPointsFromBasko(++attempt);
            }
          }, 3500);
        }
      } catch (err) {
        console.log(err);
      }
    },
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    },
    fetchFooterLinksMenu() {
      CmService.getMenuByLocation("footer?v=1").then(
        data => (this.footerLinks = data)
      );
    },
    async fetchApplicationConfig() {
      try {
        let result = await CategoryService.getCategoryBySlug("application");
        this.applicationConfig = result;

        this.setApplicationConfig(
          this.$ebsn.meta(this.applicationConfig, "template_model", {})
        );
      } catch (err) {
        console.log(err);
      }
    },
    async onResume() {
      var _this = this;
      var hash;
      global.EventBus.$emit("resume");
      setTimeout(async function() {
        hash = findIndex(global.config.hashNotReload, function(o) {
          return window.location.hash.indexOf(o) !== -1;
        });
        var needRestart =
          new Date().getTime() - _this.$store.state.app.lastStartTime >
          global.config.reloadAfterMillis;
        if (hash === -1 && needRestart) {
          global.EventBus.$emit("restart");
        } else {
          if (
            hash === -1 &&
            window.sessionStorage.getItem("is-authenticated")
          ) {
            await _this.loadCart();
          }
          global.EventBus.$emit("reload");
        }
      }, 100);
    },
    async handleLogin() {
      this.doLogin("response419");
    },
    async onDeepLinks(path, queryString) {
      var _this = this;
      var currentQuery = {};
      console.log("onDeepLinks :::: ", path);
      console.log("onDeepLinks queryString :::: ", queryString);
      console.log("_this.$router :::: ", _this.$router);
      setTimeout(async function() {
        if (queryString) {
          currentQuery.q = queryString.replace("q=", "").replace(/%20/g, " ");
          console.log("onDeepLinks currentQuery :::: ", currentQuery);
          _this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        } else {
          _this.$router.push(path);
        }
      }, 100);
    },
    resolveRoute(data) {
      var _this = this;
      var currentQuery = {};
      console.log("resolveRoute :::: ", data);
      if (global.config.domainWhitelist.includes(data.host)) {
        let queryString = data.queryString;
        let resolved = this.$router.resolve({
          path: data.path
        });
        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute"
        ) {
          setTimeout(async function() {
            if (queryString) {
              var keyValPairs = queryString.split("&");
              for (var pairNum in keyValPairs) {
                var key = keyValPairs[pairNum].split("=")[0];
                if (key.length > 0) {
                  if (typeof currentQuery[key] === "undefined")
                    currentQuery[key] = "";
                  currentQuery[key] = keyValPairs[pairNum].split("=")[1];
                }
              }
              currentQuery.q = queryString
                .replace("q=", "")
                .replace(/%20/g, " ");
              console.log("onDeepLinks currentQuery :::: ", currentQuery);
              _this.$router.push({
                path: data.path,
                query: currentQuery
              });
            } else {
              _this.$router.push(data.path);
            }
          }, 100);
        } else {
          if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
            //if app open on _system browser
            window.cordova.InAppBrowser.open(data.url, "_system");
          }
        }
      } else {
        if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
          //if app open on _system browser
          window.cordova.InAppBrowser.open(data.url, "_system");
        }
      }
    },
    onAppTrackingTransparency() {
      var _this = this;
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      if (
        _this.$platform_is_cordova &&
        platform == "ios" &&
        window.plugins.impacTracking
      ) {
        console.log("Calling onAppTrackingTransparency");
        window.plugins.impacTracking.trackingAvailable(
          function(available) {
            if (!available) {
              console.log(
                "Tracciamento non disponibile o disabilitato dall'utente"
              );
              window.plugins.impacTracking.canRequestTracking(
                function(result) {
                  // Show results in the console
                  console.log(result);
                  if (result == true) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                    window.plugins.impacTracking.requestTracking(
                      undefined,
                      function(res) {
                        console.log(res);
                        _this.attValue = res;
                        if (res == true) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.submitCustomConsent(true, true, true);
                        }
                        if (res == false) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.hide();
                        }
                      },
                      function(err) {
                        console.log(err);
                        //TODO: tracking non abilitato
                      }
                    );
                  }
                },
                function(err) {
                  console.log(err);
                  //TODO: c'è stato un errore: tracking non abilitato
                }
              );
            } else {
              window.plugins.impacTracking.requestTracking(
                undefined,
                function(res) {
                  console.log(
                    "Calling onAppTrackingTransparency; already defined"
                  );
                  console.log("AppTracking enabled: " + res);
                  _this.attValue = res;
                  // eslint-disable-next-line no-undef
                  var stats = Cookiebot.consent.statistics;
                  // eslint-disable-next-line no-undef
                  var mrkg = Cookiebot.consent.marketing;
                  // eslint-disable-next-line no-undef
                  var pref = Cookiebot.consent.preferences;
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  if (res == false) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                  }
                },
                function(err) {
                  console.log(err);
                }
              );
            }
          },
          function(err) {
            console.log(err);
          }
        );
      }
    },
    async refreshLogin() {
      if (global.config.pushNotificationEnabled) {
        console.log("REFRESH LOGIN - REGISTER FIREBASE DEVICE");
        // Al Deviceready facciamo un refresh login per controllare se l'utente è ancora collegato
        // await SecurityService.refreshLogin();
        // await this.registerFirebaseDevice();
      }
    },
    ...mapActions({
      loadCart: "cart/loadCart",
      updateCheckoutAwardsBeginDate: "custom/updateCheckoutAwardsBeginDate",
      updateCheckoutAwardsEndDate: "custom/updateCheckoutAwardsEndDate",
      updateCheckoutAwardsProducts: "custom/updateCheckoutAwardsProducts",
      setApplicationConfig: "custom/setApplicationConfig"
    }),
    isPlatformIos() {
      if (this.isCordova && window.cordova.platformId.toLowerCase() === "ios") {
        this.platformIos = true;
      }
    },
    async checkMissingDisclaimer() {
      let response = await UserService.checkUserDisclaimer(2);
      if (
        response &&
        response.registrationModules &&
        response.registrationModules.length > 0
      ) {
        await this.openDialog(CheckDisclaimerDialog, {
          waitForResult: true,
          fullscreen: false
        });
      }
    },
    initFreshChat(preChatTemplate) {
      var freshKey = process.env.VUE_APP_FRESHCHAT_SITE_KEY;
      if (freshKey != null) {
        window.fcSettings = {
          token: freshKey,
          host: "https://wchat.eu.freshchat.com",
          config: {
            cssNames: {
              //The below element is mandatory. Please add any custom class or leave the default.
              widget: "custom_fc_frame",
              //The below element is mandatory. Please add any custom class or leave the default.
              expanded: "custom_fc_expanded"
            },
            headerProperty: {
              direction: "ltr" //will move widget to left side of the screen
            }
          },
          onInit: function() {
            console.log("widget init");
            // eslint-disable-next-line no-undef
            fcPreChatform.fcWidgetInit(preChatTemplate);
          }
        };
      }
    }
  },
  async created() {
    await setLocale("it"); //abilitare se si vuole usare la risorsa di localizzazione remota
    this.i18nLoaded = true;
    var _this = this;
    _this.load404 = false;
    this.fetchFooterLinksMenu();
    this.fetchSiteLinks();
    this.fetchApplicationConfig();
    try {
      if (
        window.sessionStorage.getItem("is-authenticated") ||
        window.sessionStorage.getItem("X-Ebsn-Session") ||
        window.localStorage.getItem("X-Ebsn-Account") ||
        window.location.href.indexOf("checkUser") !== -1
      ) {
        await _this.loadCart();
      } else {
        analyticsService.initCustomDimension();
      }
    } finally {
      _this.loading = false;
    }
    //generate fingerprint
    // const fp = await FingerprintJS.load({ monitoring: false });
    // this.updateFingerprint(await fp.get());

    global.EventBus.$on("isNotLoggedIn", () => {
      _this.updateAuth();
      _this.handleLogin();
    });
    global.EventBus.$on("restart", () => {
      _this.updateLastStartTime();
      window.location.reload();
    });
    global.EventBus.$on("resetFilters", () => {
      _this.resetFilters(Math.random());
    });
    setTimeout(function() {
      _this.key = _this.key + 1;
    }, 3000);
    //eventi legati a cordova, partono solo al deviceready che viene scatenato da cordova quanto la app è pronta
    document.addEventListener(
      "deviceready",
      () => {
        setTimeout(function() {
          if (window.innerWidth < 600) {
            screen.orientation.lock("portrait");
          }
        }, 1500);

        //save last start time
        _this.updateLastStartTime();

        document.addEventListener("resume", _this.onResume, false);

        //TODO: cambiare gestione da config.js alla router clicked
        window.IonicDeeplink.route(
          global.config.deeplinks,
          function(match) {
            _this.resolveRoute(match.$link);
          },
          function(nomatch) {
            console.log("NOMATCH :::: ", nomatch);
            _this.resolveRoute(nomatch.$link);
          }
        );

        //con x secondi controllo se c'è una versione più recente
        this.checkVersion();

        this.isPlatformIos();

        if (this.isCordova) {
          window.open = window.cordova.InAppBrowser.open;
        }

        this.onAppTrackingTransparency();

        // Plugin Keyboard
        window.addEventListener("keyboardDidHide", () => {
          // Describe your logic which will be run each time keyboard is closed.
          console.log("keyboardDidHide");
          _this.keyboardOpen = false;
        });
        window.addEventListener("keyboardDidShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardDidShow");
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardWillShow");
          //iOS fix
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillHide", () => {
          // Describe your logic which will be run each time when keyboard is about to be closed.
          console.log("keyboardWillHide");
          //iOS fix

          _this.keyboardOpen = false;
        });
        window.addEventListener(
          "CookiebotOnAccept",
          function() {
            _this.attValue = true;
          },
          false
        );
        window.addEventListener(
          "CookiebotOnDecline",
          function() {
            console.log("CookiebotOnDecline");
            _this.attValue = false;
          },
          false
        );
        window.addEventListener(
          "CookiebotOnDialogDisplay",
          function() {
            console.log("Calling CookiebotOnDialogDisplay");
            // eslint-disable-next-line no-undef
            var hasResponded = Cookiebot.consented || Cookiebot.declined;
            if (
              window.cordova &&
              window.cordova.platformId.toLowerCase() == "ios" &&
              window.plugins.impacTracking &&
              !_this.attResponse
            ) {
              if (_this.attValue) {
                console.log("Ho cliccato su consenti");
                // Ho cliccato su "Consenti"
                // eslint-disable-next-line no-undef
                var stats = Cookiebot.consent.statistics;
                // eslint-disable-next-line no-undef
                var mrkg = Cookiebot.consent.marketing;
                // eslint-disable-next-line no-undef
                var pref = Cookiebot.consent.preferences;
                if (!hasResponded) {
                  console.log("Not responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(true, true, true);
                  _this.attResponse = true;
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                } else {
                  console.log("Has responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  _this.attResponse = true;
                }
              } else {
                console.log("Ho cliccato su non consentire");
                //Ho cliccato su "non consentire"
                // eslint-disable-next-line no-undef
                var declined = Cookiebot.declined;
                // eslint-disable-next-line no-undef
                Cookiebot.withdraw();
                _this.attResponse = true;
                if (!declined) {
                  console.log("First time");
                  //first time
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                }
              }
            }
          },
          false
        );

        this.refreshLogin();
      },
      false
    );
    if (this.isCordova) {
      //add platfrm to body element
      let platform = window.cordova.platformId;
      document.body.classList.add(platform);
    } else {
      //add platfrm to body element
      let platform = navigator.userAgentData
        ? navigator.userAgentData.platform
        : navigator.platform;
      document.body.classList.add(platform);
      this.checkOsx();
    }
  },
  mounted() {
    // Avoid loading Cookiebot if the page is server rendered (Prerender.io)
    // DOC: Prerender.io crawler User-Agent will always contain: "Prerender (+https://github.com/prerender/prerender)" - src:   https://docs.prerender.io/docs/33-overview-of-prerender-crawlers
    // if (!navigator.userAgent.match("Prerender|GTmetrix|Googlebot")) {
    //   let cookiebotScript = document.createElement("script");
    //   cookiebotScript.setAttribute("id", "Cookiebot");
    //   cookiebotScript.setAttribute(
    //     "src",
    //     "https://consent.cookiebot.com/uc.js"
    //   );
    //   cookiebotScript.setAttribute(
    //     "data-cbid",
    //     "14ba8ff4-6ff8-4b2d-a2d8-9f112b494e56"
    //   );
    //   cookiebotScript.setAttribute("type", "text/javascript");
    //   cookiebotScript.async = true;
    //   document.head.appendChild(cookiebotScript);
    // }
  },
  beforeDestroy() {
    global.EventBus.$off("isNotLoggedIn");
    global.EventBus.$off("restart");
    global.EventBus.$off("resetFilters");
  },
  watch: {
    cart() {
      var _this2 = this;
      if (this.cart) {
        var preChatTemplate = {
          //Form header color and Submit button color.
          mainbgColor: "#00a38a",
          //Form Header Text and Submit button text color.
          maintxColor: "#fff",
          //Chat Form Title
          heading: "Basko Live Chat",
          //Chat form Welcome Message
          textBanner:
            "Prima di iniziare a chattare, inserisci i seguenti dati.",
          //Submit Button Label.
          SubmitLabel: "Invia",
          //Fields List - Maximum is 5
          //All the values are mandatory and the script will not work if not available.
          fields: {
            field1: {
              //Type for Name - Do not Change
              type: "name",
              //Label for Field Name, can be in any language
              label: "Nome",
              //Default - Field ID for Name - Do Not Change
              fieldId: "name",
              //Required "yes" or "no"
              required: "yes",
              //Error text to be displayed
              error: "Inserisci un nome valido."
            },
            field2: {
              //Type for Email - Do Not Change
              type: "email",
              //Label for Field Email, can be in any language
              label: "Email",
              //Default - Field ID for Email - Do Not Change
              fieldId: "email",
              //Required "yes" or "no"
              required: "yes",
              //Error text to be displayed
              error: "Inserisci un email valida."
            }
          }
        };
        this.initFreshChat(preChatTemplate);
        window.fcWidget.on("widget:opened", function() {
          console.log("prewidget was opened");
          setTimeout(function() {
            if (_this2.cart) {
              var firstName = _this2.cart.user.firstName;
              var lastName = _this2.cart.user.lastName;
              var email = _this2.cart.user.email;
              if (firstName && lastName) {
                //$window.livechat.Visitor.setName(firstName + " " + lastName);
                window.document.getElementById("chat-fc-nome").value =
                  firstName + " " + lastName;
              }
              if (email) {
                //$window.livechat.Visitor.setEmail(email);
                window.document.getElementById("chat-fc-email").value = email;
              }
            }
          }, 200);
        });
      }
    },
    isAuthenticated(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        // la chiamata ai sistemi basko per avere i punti è lenta e spesso ci mette troppo per mandarci i punti, quindi rifacciamo la chiamata del cart
        if (get(this.cart, "user.userPointsCounters[0].value") == -1) {
          this.checkGotPointsFromBasko(0);
        }
      }
    }
  }
};
</script>
