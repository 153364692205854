<template>
  <v-card class="cart-summary px-3 text-right" flat v-if="cart.cartId > 0">
    <v-card-text class="default--text d-flex flex-column justify-center">
      <PurchaseMinimum />
      <div v-if="cart.giftTotal > 0" class="advantages pa-0">
        <span class="advantages-text font-weight-bold">
          {{ $t("cartSummary.giftTotal") }}
        </span>
        <strong class="advantages-value font-weight-bold value">
          -{{ $n(cart.giftTotal, "currency") }}
        </strong>
      </div>
      <div
        class="text-h3 d-flex justify-end align-center font-weight-bold text-uppercase"
      >
        <span>{{ $t("cartSummary.grossTotal") }}</span
        ><span class="value">{{ $n(cart.totalPrice, "currency") }}</span>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <template v-if="cart.totalItems > 0">
        <v-btn
          class="checkout-btn"
          outlined
          color="primary"
          large
          depressed
          @click="emptyCartConfirm"
          min-width="33%"
          >{{ $t("cart.button.emptyCart") }}</v-btn
        >
        <v-btn
          class="checkout-btn"
          :to="{ name: 'Cart' }"
          outlined
          color="primary"
          large
          depressed
          min-width="33%"
          >{{ $t("cart.button.goToCartDetail") }}</v-btn
        >
        <v-btn
          class="checkout-btn"
          :to="{ name: 'Checkout' }"
          color="primary"
          large
          depressed
          min-width="33%"
          >{{ $t("cart.button.goToPayment") }}</v-btn
        >
      </template>
      <template v-else>
        <v-btn
          class="checkout-btn"
          color="primary"
          large
          outlined
          block
          depressed
          :to="{ name: 'Home' }"
          @click="updatedDrawerRight(false)"
          min-width="33%"
          >{{ $t("cart.button.continue") }}</v-btn
        >
      </template>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style lang="scss">
.cart-summary {
  .v-card__text {
    height: 75px;
  }
  .value {
    min-width: 150px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-card__actions {
      padding: 8px 0;
    }
  }
}
</style>
<script>
import PurchaseMinimum from "@/components/cart/PurchaseMinimum.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "CartSummary",
  props: ["full"],
  components: {
    PurchaseMinimum
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      updatedDrawerRight: "app/updatedDrawerRight",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>
