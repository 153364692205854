import base from "./base";
import wordpress from "./wordpress";
import catalog from "./catalog";
import profile from "./profile";
import eugenio from "./eugenio";
import checkout from "./checkout";
import delivery from "./delivery";
import notifications from "./notifications";

const routes = [
  {
    path: "/",
    name: "HomeSite",
    meta: {
      site: "site",
      slug: ["home-site"]
    },
    props: true,
    component: () => import("@/views/HomeSite.vue")
  },
  {
    path: "/spesa-online",
    name: "Home",
    props: true,
    meta: {
      site: "ecommerce",
      slug: ["shop"]
      // slug: ["shop", "spesa-online"]
    },
    component: () => import("@/views/Home.vue")
  },
  ...base,
  ...wordpress,
  ...profile,
  ...notifications,
  ...eugenio,
  ...checkout,
  ...delivery,
  ...catalog
];

export default routes;
