let routes = [
  {
    path: "/volantino-offerte",
    meta: {
      site: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "Leaflets",
        meta: {
          site: "site",
          slug: "volantino-offerte",
          breadCrumb: "Volantini"
          // ,
          // postName: "leaflets"
        },
        component: () => import("@/views/catalog/Leaflets.vue")
      },
      {
        path: "/volantino-offerte/:leafletId",
        name: "Leaflet",
        meta: {
          site: "site",
          slug: "leaflet-detail"
        },
        props: route => ({
          leafletId: route.params.leafletId
        }),
        component: () => import("@/views/catalog/Leaflet.vue")
      }
    ]
  },
  {
    path: "/supermercati",
    meta: {
      breadCrumb: "Punti Vendita",
      site: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "WarehouseMap",
        meta: {
          site: "site",
          slug: "warehouse"
        },
        component: () => import("@/views/store/WarehouseMap.vue")
      },
      {
        path: "/supermercati/:warehouseId",
        name: "WarehouseDetail",
        meta: {
          breadCrumb: "Punto Vendita",
          slug: "warehouse",
          site: "site"
        },
        component: () => import("@/views/store/WarehouseDetail.vue")
      }
    ]
  },
  {
    //gestione gerarchica del catalogo per poter gestire il reset filters all'ingresso.
    path: "/catalog",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    beforeEnter(to, from, next) {
      global.EventBus.$emit("resetFilters");
      next();
    },
    children: [
      {
        path: "/search",
        name: "Search",
        component: () => import("@/views/catalog/Search.vue"),
        meta: { slug: "search" },
        props: route => ({
          query: route.params,
          parent_category_id: route.params.parent_category_id,
          filter: route.params.filter,
          mode: "search"
        })
      },
      {
        path: "/fast-search",
        name: "FastSearch",
        component: () => import("@/views/catalog/FastSearch.vue"),
        props: route => ({
          query: route.query.q
        }),
        meta: { slug: "fast-search" }
      },

      {
        path: "/product/:slug",
        name: "Product",
        component: () => import("@/views/catalog/Product.vue"),
        props: route => ({
          slug: route.params.slug
        }),
        meta: {
          keepAlive: true,
          product: true
        }
      },
      {
        path: "/*",
        name: "Category",
        component: () => import("@/views/catalog/Category.vue"),
        meta: {
          keepAlive: true
        },
        //commento
        props: route => ({
          slug: route.params.pathMatch,
          categoryId: route.query.categoryId
        })
      }
    ]
  }
];

export default routes;
