<template>
  <v-container fluid class="navbar-line navbar-site">
    <div class="navbar-row d-flex align-center justify-space-between">
      <!-- menu col -->
      <div
        class="menu-btn order-0 d-flex d-md-none justify-start align-center column pl-4"
      >
        <div
          class="justify-left align-center"
          v-if="
            ($platform_is_cordova && hasPrevious) ||
              ($platform_is_cordova && hasHistory)
          "
        >
          <v-btn
            @click="historyBack()"
            class="back-button no-border"
            aria-label="Torna Indietro"
            icon
          >
            <v-icon class="default--text back-arrow">$arrowBack</v-icon>
          </v-btn>
        </div>
        <v-btn
          v-if="site"
          aria-label="Apri menu categorie"
          icon
          class="open-menu-btn"
          @click.prevent.stop="drawerLeft = !drawerLeft"
        >
          <v-icon color="grey darken-1">$menu</v-icon>
          {{ $t("navbar.menu") }}
        </v-btn>
      </div>
      <!-- logo col -->
      <div class="d-flex align-center order-1">
        <router-link
          :to="site == 'site' ? { name: 'HomeSite' } : { name: 'Home' }"
          class="d-flex align-center justify-center justify-md-start"
        >
          <!-- logo desktop -->
          <img
            class="px-0 logo-desktop d-none d-md-flex"
            src="/logo/logo-site.svg"
            alt="Logo Basko"
            :style="{ height: '42px' }"
          />
          <!-- logo mobile -->
          <img
            class="px-0 logo-mobile mx-auto d-flex d-md-none"
            src="/logo/logo-site-mobile.svg"
            alt="Logo Basko"
            :style="{ height: '34px' }"
          />
        </router-link>
      </div>
      <!-- search col -->
      <div
        class="order-4 order-md-2 d-none d-md-flex px-md-4 column mt-1 mt-md-0 px-4 justify-start site-links-container no-wrap"
        :class="{ closed: showSearchBox }"
      >
        <v-btn
          v-for="link in siteLinks"
          :key="link.ID"
          :href="link.url"
          @click="clicked"
          text
          link
        >
          {{ link.title }}
        </v-btn>
      </div>
      <!-- buttons col -->
      <div class="order-2 order-md-3 d-flex justify-end align-center">
        <v-icon
          v-show="showSearchBox"
          @click="showSearchBox = false"
          aria-label="Chiudi la ricerca"
        >
          $next
        </v-icon>

        <WpSearchInput
          v-if="$vuetify.breakpoint.mdAndUp"
          @toggle="value => (showSearchBox = value)"
        />
        <template v-if="!isAuthenticated">
          <!-- sloggato -->
          <v-btn
            class="mr-2 profile-btn"
            aria-label="Accedi"
            color="white"
            @click.stop="handleLogin()"
            tile
            depressed
          >
            <v-icon color="grey darken-1">$profile</v-icon>
            <div class="username d-none d-lg-block  text-caption ml-2">
              <span>{{ $t("navbar.userMessage") }}</span> <br />
              <strong>{{ $t("navbar.userMessageNotLogged") }}</strong>
            </div>
          </v-btn>
          <router-link
            :to="{ name: 'Home' }"
            class="d-none d-md-flex align-center justify-center"
          >
            <!-- logo desktop -->
            <img
              class="px-0 logo-desktop"
              src="/logo/go-to-shop.svg"
              alt="Logo Basko Spesa Online"
              :style="{ height: '42px' }"
            />
          </router-link>
        </template>
        <template v-else>
          <!-- loggato -->
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aria-label="Vai al profilo"
                v-bind="attrs"
                v-on.stop="on"
                tile
                depressed
                color="white"
                class="profile-btn"
              >
                <v-icon color="primary">$profileFull</v-icon>
                <div class="username d-none d-lg-block  text-caption ml-2">
                  <span>{{ $t("navbar.userMessage") }}</span> <br />
                  <strong>{{ cart.user.firstName }}</strong>
                </div>
              </v-btn>
            </template>
            <UserMenu />
          </v-menu>

          <router-link
            :to="{ name: 'Home' }"
            class="d-none d-md-flex align-center justify-center"
          >
            <!-- logo desktop -->
            <img
              @click="checkOnboarding"
              class="px-0 logo-desktop"
              src="/logo/go-to-shop.svg"
              alt="Logo Basko Spesa Online"
              :style="{ height: '42px' }"
            />
          </router-link>
        </template>
      </div>
    </div>
    <div class="search-row px-4 mt-5" v-if="$vuetify.breakpoint.smAndDown">
      <wp-search-input :closable="false" />
    </div>
  </v-container>
</template>
<style lang="scss">
.navbar-site {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .v-input.search-input {
      min-width: 350px;
    }
  }
}
.site-links-container {
  transition: width 0.5s ease;
  overflow: hidden;
  width: 100%;
  &.closed {
    width: 0;
  }
}
</style>
<script>
import UserMenu from "@/components/navigation/UserMenu.vue";
import WpSearchInput from "@/components/navigation/WpSearchInput.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";

import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Navbar",
  mixins: [login, cartInfo, clickHandler],
  components: {
    UserMenu,
    WpSearchInput
  },
  props: {
    siteLinks: { type: Array, default: () => [] }
  },
  data() {
    return {
      showSearchBox: false
    };
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      site: "custom/getSite"
    }),
    hasPrevious() {
      return this.$routerHistory.hasPrevious() ? true : false;
    },
    hasHistory() {
      return window.history.length > 0 ? true : false;
    },
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft"
    }),
    checkOnboarding($event) {
      if (this.isLoggedIn) {
        if (this.cart.shippingAddress.autoAssigned) {
          $event.preventDefault();
          this.$router.push({
            name: "DeliveryServiceView"
          });
        } else if (this.cart.timeslot.autoAssigned) {
          $event.preventDefault();
          this.$router.push({
            name: "TimeslotSelectorView"
          });
        }
      }
    },
    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "HomeSite",
            path: "/"
          });
        }, 200);
      }
    },
    toggleShowSearch(value) {
      this.showSearchBox = value;
    },
    goBack() {
      this.$router.go(-1);
    },
    async handleLogin() {
      await this.needLogin("navbar");
      // if (await this.needLogin("navbar")) {
      //   if (await this.needAddress()) {
      //     await this.needTimeslot();
      //   }
      // }
    },
    historyBack() {
      window.history.back();
    }
  },
  mounted() {}
};
</script>
