<template>
  <div v-if="warehouse" class="navbar-fav-wh">
    <v-icon color="black" class="mr-1">$star</v-icon>
    <span class="selected-address-text black--text">
      {{ $t("navbar.favoutireWh") }}
      {{ $t("navbar.favouriteWhFormat", warehouse.address) }}
    </span>
    <FavWarehouseSelector />
  </div>
</template>

<script>
import AddressService from "~/service/addressService";
import baskoCustomService from "@/service/baskoCustomService";
import FavWarehouseSelector from "../warehouse/FavWarehouseSelector.vue";

export default {
  name: "NavbarFavWh",
  props: {
    warehouseId: { type: Number, required: true }
  },
  data() {
    return {
      loading: false,
      warehouses: null,
      warehouse: null,
      dialog: false
    };
  },
  methods: {
    async getWarehouses() {
      let res = await AddressService.findWarehouse();
      if (res && res.warehouses) {
        this.warehouses = res.warehouses;
      }
    },
    async handleFavWhChange(wh) {
      this.warehouseId = wh;
      await this.fetchwarehouse();
    },
    async fetchwarehouse() {
      try {
        this.loading = true;
        let res = await AddressService.getWarehouseById(this.warehouseId);
        this.warehouse = res;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async setFavWarehouse() {
      const user = await baskoCustomService.setFavWarehouse(this.warehouse);
      console.log("user", user);
    }
  },
  async mounted() {
    await this.fetchwarehouse();
    await this.getWarehouses();
    global.EventBus.$on("fav-wh-changed", this.handleFavWhChange);
  },
  watch: {
    warehouseId() {
      this.fetchwarehouse();
    }
  },
  components: { FavWarehouseSelector }
};
</script>
