<template>
  <div
    class="mr-0 mr-md-5 mt-3 mt-md-0 order-1 order-lg-2 d-flex justify-center"
  >
    <router-link :to="$t('register.user.privacyLink')">{{
      $t("footer.links.privacyPolicy")
    }}</router-link>
    &nbsp;|&nbsp;
    <router-link :to="$t('register.user.cookiesLink')">{{
      $t("footer.links.cookiePolicy")
    }}</router-link>
    &nbsp;|&nbsp;
    <a class="cookiebot-widget" @click="openCookieBotWidget">{{
      $t("footer.links.ManageCookiePolicy")
    }}</a>
  </div>
</template>
<style lang="scss">
.privacy-links {
  text-align: center;
  a {
    text-decoration: none !important;
  }
}
</style>
<script>
export default {
  name: "PrivacyLinks",
  methods: {
    openCookieBotWidget() {
      // eslint-disable-next-line no-undef
      Cookiebot.show();
    }
  }
};
</script>
