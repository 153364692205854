<template>
  <div class="cart-info-list d-flex pa-2">
    <div class="avatar ma-1 primary">
      <router-link
        :to="{ name: 'DeliveryServiceView', query: { back: $route.fullPath } }"
      >
        <img
          class="service-img"
          alt="Logo servizio"
          aria-label="Logo servizio scelto"
          :src="editIconImg"
        />
      </router-link>
    </div>
    <div class="d-flex flex-column align-left w-100">
      <div class="d-flex align-center text-body-1 mt-1">
        <v-btn
          aria-label="Cambia indirizzo di consegna"
          class="cart-info-button text-none px-0"
          :class="isCart ? 'text-body-1' : 'text-caption'"
          :disabled="!editableAddress"
          color="primary"
          text
          tile
          small
          elevation="0"
          rounded
          :to="{
            name: 'AddressSelectorView',
            query: { deliveryServiceId: getDeliveryServiceId }
          }"
        >
          <label v-if="isCart" class="d-none d-sm-inline default--text"
            >{{ $t("cart.cartInfo.editableAddress") }}&nbsp;
          </label>
          <span class="info-text text-uppercase font-weight-bold">
            {{
              isAddressAssigned
                ? shippingAddress
                : $t("navbar.navbarSiteSelection.addressAutoassigned")
            }}
          </span>
          <v-icon right v-if="!isCart">$edit</v-icon>
        </v-btn>
        <!-- icon to be shown in cart drawer -->
        <v-spacer />
        <v-icon
          @click="openAddressSelector"
          v-if="isCart"
          aria-label="Cambia indirizzo di consegna"
          color="default"
          class="cart-edit-icon ml-2 d-inline"
          >$edit</v-icon
        >
      </div>
      <div class="d-flex align-center mt-2">
        <v-btn
          aria-label="Cambia orario di consegna"
          class="cart-info-button text-none px-0"
          :class="isCart ? 'text-body-1' : 'text-caption'"
          :disabled="!editableTimeslot"
          color="primary"
          text
          tile
          small
          elevation="0"
          rounded
          :to="{
            name: 'TimeslotSelectorView',
            query: { deliveryServiceId: getDeliveryServiceId }
          }"
        >
          <label v-if="isCart" class="d-none d-sm-inline default--text">
            {{ $t("cart.cartInfo.timeslot") }}
            &nbsp;
          </label>
          <span class="info-text font-weight-bold">
            {{ timeslot }}
          </span>
          <v-icon right v-if="!isCart">$edit</v-icon></v-btn
        >
        <!-- icon to be shown in cart drawer -->
        <v-spacer />
        <v-icon
          v-if="isCart"
          aria-label="Cambia orario di consegna"
          color="default"
          class="cart-edit-icon"
          @click="openTimeslotSelector"
          >$edit</v-icon
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.cart-info-list {
  position: relative;
  .info-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .avatar {
    border-radius: 4px;
    height: 60px;
    padding: 9px 2px;
    img {
      height: 35px;
    }
  }
  .v-btn.cart-info-button {
    justify-content: left;
    font-size: 12px;
    height: 20px !important;
    letter-spacing: normal;
    max-width: 85%;
    .v-btn__content {
      overflow: hidden;
      text-overflow: ellipsis;
      flex-direction: column;
      justify-content: left;
      align-items: start;
      line-height: 1;
      label {
        font-size: 11px;
      }
    }
    .v-icon {
      font-size: $font-size-root;
      height: 14px;
      width: 14px;
    }
  }
}
</style>
<script>
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

import { mapState, mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: {
    isCart: { type: Boolean, required: false }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isAddressAssigned: "cart/isAddressAssigned"
    }),
    editIconImg() {
      return this.$t(
        "navbar.service." + this.getDeliveryServiceId + ".editIcon",
        {
          color: this.color
        }
      );
    },
    customAddress() {
      let shippingAddress = get(this.cart, "shippingAddress");
      if (shippingAddress) {
        return `${shippingAddress.addressName} - ${shippingAddress.address1}`;
      }
      return "";
    }
  }
};
</script>
