<template>
  <v-list :dense="isCordova" class="category-tree site-navigation-tree">
    <v-list-item class="hidden-md-and-up justify-end">
      <v-list-item-title class="font-weight-bold"
        >{{ $t("categories.siteTitle") }}
      </v-list-item-title>
      <v-list-item-action @click.prevent.stop="drawerLeft = !drawerLeft">
        <v-icon>$close</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      v-for="link in siteLinks"
      :key="link.ID"
      :href="link.url"
      @click="clicked"
    >
      <v-list-item-title class="font-weight-bold">
        {{ link.title }}
      </v-list-item-title>
    </v-list-item>

    <template v-if="isCordova">
      <!-- show here footer menu in app -->
      <v-list-group
        v-for="(link, index) in links"
        :key="index"
        class="footer-links-group"
        append-icon=""
      >
        <template v-slot:prependIcon>
          <v-icon class="cat-1-icon" small>$next</v-icon>
        </template>
        <template v-slot:activator>
          <v-list-item-title class="font-weight-bold">
            {{ link.title }}
          </v-list-item-title>
        </template>
        <v-list-item
          class="footer-link"
          v-for="child in link.children"
          :key="child.ID"
        >
          <a
            :href="child.url"
            class="text-decoration-none default--text my-1 pl-8"
            :target="child.target || '_self'"
            @click="clickLink"
          >
            <span :class="child.classes">{{ child.title }}</span>
          </a>
        </v-list-item>
      </v-list-group>
      <div class="social-block">
        <h4
          class="text-body-1 text-center font-weight-bold text-sm-left mt-4 mx-4"
        >
          {{ $t("footer.socialColumn.title") }}
        </h4>
        <div class="d-flex flex-column align-center text-center">
          <span class="d-block default--text my-1 sub-text">{{
            $t("footer.socialColumn.proposal")
          }}</span>
          <div class="social-url-container social-app d-flex align-center mt-2">
            <a
              class="social-url"
              :href="$t('footer.socialColumn.fbUrl')"
              target="_blank"
              @click="clickLink"
            >
              <img
                src="/img_layout/footer/facebook.svg"
                alt="facebook"
                title="facebook"
              />
            </a>
            <a
              class="social-url"
              :href="$t('footer.socialColumn.ytUrl')"
              target="_blank"
              @click="clickLink"
            >
              <img
                src="/img_layout/footer/youtube.svg"
                alt="youtube"
                title="youtube"
              />
            </a>
            <a
              class="social-url"
              :href="$t('footer.socialColumn.igUrl')"
              target="_blank"
              @click="clickLink"
            >
              <img
                src="/img_layout/footer/instagram.svg"
                alt="instagram"
                title="instagram"
              />
            </a>
          </div>
        </div>
      </div>
    </template>
  </v-list>
</template>
<style lang="scss">
.site-navigation-tree {
  .v-list-item {
    // border-bottom: 1px solid var(--v-grey-lighten2);
  }

  .footer-links-group.v-list-group.v-list-group--active {
    .v-list-item__icon.v-list-group__header__prepend-icon .v-icon.cat-1-icon {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
  }
  .v-list-group {
    .v-list-item__icon.v-list-group__header__prepend-icon {
      min-width: 16px;
    }
  }
}
.sub-text {
  font-size: 14px !important;
}
.social-app {
  gap: 25px;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import { mapState, mapActions } from "vuex";
import CmService from "~/service/cmService";

export default {
  name: "SiteNavigationDrawer",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true }
  },
  data() {
    return {
      siteLinks: []
    };
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft
    }),
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    }
  },
  methods: {
    async fetchSiteLinks() {
      let res = await CmService.getMenuByLocation("primary?v=1");
      this.siteLinks = res;
      // data => (this.footerLinks = data);
    },
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft"
    }),
    clickLink: function(event) {
      if (
        (event.target &&
          event.target.className &&
          event.target.className.includes("cookiebot-widget")) ||
        (event.target &&
          event.target.children &&
          event.target.children.length > 0 &&
          event.target.children[0].className.includes("cookiebot-widget"))
      ) {
        if (window.Cookiebot) {
          window.Cookiebot.show();
        }
      } else {
        this.clicked(event);
      }
    }
  },
  created() {
    this.fetchSiteLinks();
  }
};
</script>
