<template>
  <!-- top menu -->
  <div class="navigation-selector-container">
    <v-container fluid class="d-flex justify-space-between align-center h-100">
      <div class="navigation-button-container d-flex">
        <v-btn
          text
          link
          color="black"
          class="navigation-button underline"
          :class="site == 'site' ? 'selected' : ''"
          :to="{ name: 'HomeSite' }"
        >
          <span class="d-none d-md-flex align-center mr-1">
            {{ $t("navbar.navbarSiteSelection.goToSite") }}
          </span>
          <span class="btn-text">
            {{ $t("navbar.navbarSiteSelection.siteName") }}
          </span>
        </v-btn>
        <v-btn
          text
          link
          color="black"
          class="navigation-button hidden-md-and-up underline"
          :class="site == 'ecommerce' ? 'selected' : ''"
          @click="checkOnboarding"
          :to="{ name: 'Home' }"
        >
          <span class="btn-text">
            {{ $t("navbar.navbarSiteSelection.shop") }}
          </span>
        </v-btn>
      </div>
      <div
        v-if="isAuthenticated"
        class="navbar-address-selector-opener d-none d-md-flex align-center"
      >
        <navbar-delivery-service
          v-if="site == 'ecommerce'"
          :shippingAddress="shippingAddress"
        />
        <navbar-fav-wh
          v-else-if="cart.user.defaultStoreAddress.addressId"
          :warehouse-id="cart.user.defaultStoreAddress.addressId"
        />
        <v-btn
          v-else
          depressed
          text
          link
          :to="{
            name: 'ProfileUpdate',
            query: {
              navToRefName: 'favouriteWh'
            }
          }"
          class="text-underline"
        >
          {{ $t("navbar.navbarSiteSelection.chooseFavWh") }}
        </v-btn>
      </div>
      <template v-else-if="applicationConfig">
        <v-btn
          class="slogged-btn d-none d-md-flex"
          :to="{
            path: $ebsn.meta(
              applicationConfig,
              'template_model.NAVBAR_SLOGGED_BTN_LINK'
            )
          }"
          text
          depressed
          >{{
            $ebsn.meta(
              applicationConfig,
              "template_model.NAVBAR_SLOGGED_BTN_TEXT"
            )
          }}</v-btn
        >
      </template>
    </v-container>
  </div>
</template>
<style lang="scss">
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .v-application.site {
    .navigation-button-container {
      .navigation-button {
        display: none;
      }
    }
  }
}
.navigation-selector-container {
  .change-service-img {
    height: 56px;
    width: 56px;
    filter: invert(90%);
  }
  height: 56px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 40px;
  }
  .open-address-selector-btn {
    text-decoration: underline;
  }
  .navigation-button-container {
    .btn-text {
      font-weight: bold;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100%;
    }
    .navigation-button.underline {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        width: 50%;
        &.selected {
          .v-btn__content {
            .btn-text {
              border-bottom: 3px solid black;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";

import { mapGetters } from "vuex";
import NavbarFavWh from "./NavbarFavWh.vue";
import NavbarDeliveryService from "./NavbarDeliveryService.vue";

export default {
  components: { NavbarFavWh, NavbarDeliveryService },
  name: "NavbarSiteSelection",
  mixins: [cartInfo],
  props: {
    applicationConfig: { type: Object, required: false }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      site: "custom/getSite"
    })
  },
  methods: {
    checkOnboarding($event) {
      if (this.isAuthenticated) {
        if (this.cart.shippingAddress.autoAssigned) {
          $event.preventDefault();
          this.$router.push({
            name: "DeliveryServiceView"
          });
        } else if (this.cart.timeslot.autoAssigned) {
          $event.preventDefault();
          this.$router.push({
            name: "TimeslotSelectorView"
          });
        }
      }
    }
  }
};
</script>
