import { render, staticRenderFns } from "./NavbarSite.vue?vue&type=template&id=01152946&"
import script from "./NavbarSite.vue?vue&type=script&lang=js&"
export * from "./NavbarSite.vue?vue&type=script&lang=js&"
import style0 from "./NavbarSite.vue?vue&type=style&index=0&id=01152946&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports