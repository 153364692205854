var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-info-list d-flex pa-2"},[_c('div',{staticClass:"avatar ma-1 primary"},[_c('router-link',{attrs:{"to":{ name: 'DeliveryServiceView', query: { back: _vm.$route.fullPath } }}},[_c('img',{staticClass:"service-img",attrs:{"alt":"Logo servizio","aria-label":"Logo servizio scelto","src":_vm.editIconImg}})])],1),_c('div',{staticClass:"d-flex flex-column align-left w-100"},[_c('div',{staticClass:"d-flex align-center text-body-1 mt-1"},[_c('v-btn',{staticClass:"cart-info-button text-none px-0",class:_vm.isCart ? 'text-body-1' : 'text-caption',attrs:{"aria-label":"Cambia indirizzo di consegna","disabled":!_vm.editableAddress,"color":"primary","text":"","tile":"","small":"","elevation":"0","rounded":"","to":{
          name: 'AddressSelectorView',
          query: { deliveryServiceId: _vm.getDeliveryServiceId }
        }}},[(_vm.isCart)?_c('label',{staticClass:"d-none d-sm-inline default--text"},[_vm._v(_vm._s(_vm.$t("cart.cartInfo.editableAddress"))+"  ")]):_vm._e(),_c('span',{staticClass:"info-text text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm.isAddressAssigned ? _vm.shippingAddress : _vm.$t("navbar.navbarSiteSelection.addressAutoassigned"))+" ")]),(!_vm.isCart)?_c('v-icon',{attrs:{"right":""}},[_vm._v("$edit")]):_vm._e()],1),_c('v-spacer'),(_vm.isCart)?_c('v-icon',{staticClass:"cart-edit-icon ml-2 d-inline",attrs:{"aria-label":"Cambia indirizzo di consegna","color":"default"},on:{"click":_vm.openAddressSelector}},[_vm._v("$edit")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-center mt-2"},[_c('v-btn',{staticClass:"cart-info-button text-none px-0",class:_vm.isCart ? 'text-body-1' : 'text-caption',attrs:{"aria-label":"Cambia orario di consegna","disabled":!_vm.editableTimeslot,"color":"primary","text":"","tile":"","small":"","elevation":"0","rounded":"","to":{
          name: 'TimeslotSelectorView',
          query: { deliveryServiceId: _vm.getDeliveryServiceId }
        }}},[(_vm.isCart)?_c('label',{staticClass:"d-none d-sm-inline default--text"},[_vm._v(" "+_vm._s(_vm.$t("cart.cartInfo.timeslot"))+"   ")]):_vm._e(),_c('span',{staticClass:"info-text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.timeslot)+" ")]),(!_vm.isCart)?_c('v-icon',{attrs:{"right":""}},[_vm._v("$edit")]):_vm._e()],1),_c('v-spacer'),(_vm.isCart)?_c('v-icon',{staticClass:"cart-edit-icon",attrs:{"aria-label":"Cambia orario di consegna","color":"default"},on:{"click":_vm.openTimeslotSelector}},[_vm._v("$edit")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }