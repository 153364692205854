const routes = [
  {
    path: "/page/:postName",
    name: "Page",
    meta: {
      site: "site"
    },
    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/wp-search",
    name: "WpSearch",
    component: () => import("@/views/wordpress/Search.vue"),
    props: route => ({
      query: route.params.q,
      subtype: route.params.subtype
    }),
    meta: { slug: "search", site: "site" }
  },
  {
    path: "/faq",
    meta: {
      breadCrumb: "Faq",
      site: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "FaqCategory",
        path: "",
        meta: {
          site: "site",
          postName: "faq"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      },
      {
        path: ":categoryName",
        name: "FaqList",
        meta: {
          breadCrumb: "Dettaglio Faq",
          site: "site"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      }
    ]
  },
  {
    path: "/primacard",
    meta: {
      breadCrumb: "PrimaCard",
      site: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "PrimaCardCategory",
        path: "",
        meta: {
          site: "site",
          postName: "primacard"
        },
        component: () => import("@/views/wordpress/PrimaCardList.vue")
      },
      {
        path: ":categoryName",
        name: "PrimaCardList",
        meta: {
          breadCrumb: "Dettaglio Prima Card",
          site: "site"
        },
        component: () => import("@/views/wordpress/PrimaCardList.vue")
      }
    ]
  },
  {
    path: "/form/:formId",
    name: "NinjaForms",
    meta: {
      site: "site"
    },
    component: () => import("@/commons/components/ninjaForms/NinjaForm.vue"),
    props: true
  },
  {
    path: "/ricette",
    meta: {
      breadCrumb: "Ricette",
      meta: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "RecipeList",
        path: "",
        component: () => import("@/views/wordpress/RecipeList.vue"),
        props: route => ({
          course: route.query.course,
          ingredients: route.query.ingredients,
          cuisine: route.query.cuisine,
          s: route.query.s
        }),
        meta: {
          site: "site"
        }
      },
      {
        path: ":postName",
        name: "RecipeDetail",
        meta: {
          breadCrumb: "Dettaglio Ricetta",
          site: "site"
        },
        component: () => import("@/views/wordpress/RecipeDetail.vue")
      }
    ]
  },
  {
    path: "/articoli",
    meta: {
      breadCrumb: "Article",
      site: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "ArticleList",
        path: "",
        component: () => import("@/views/wordpress/ArticleList.vue"),
        props: route => ({
          s: route.query.s,
          cat: route.query.cat
        }),
        meta: {
          site: "site"
        }
      },
      {
        path: ":postName",
        name: "ArticleDetail",
        meta: {
          breadCrumb: "Dettaglio Articolo",
          site: "site"
        },
        component: () => import("@/views/wordpress/ArticleDetail.vue")
      }
    ]
  },
  {
    path: "/convenzioni",
    name: "Convenzioni",
    meta: {
      site: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "ConvenzioniCategory",
        path: "",
        meta: {
          site: "site"
        },
        component: () => import("@/views/wordpress/ConvenzioniList.vue")
      },
      {
        path: ":categoryName",
        name: "ConvenzioniList",
        meta: {
          site: "site"
        },
        component: () => import("@/views/wordpress/ConvenzioniList.vue")
      }
    ]
  }
];

export default routes;
