<template>
  <v-combobox
    class="wp-search-input"
    :class="{ 'wp-search-input--closed': closable && closed }"
    clearable
    filled
    solo
    dense
    v-model="selected"
    ref="autocomplete"
    :items="items"
    :search-input.sync="search"
    no-filter
    :placeholder="$t('navbar.searchWpPlaceholder')"
    flat
    item-text="name"
    item-value="name"
    hide-details
    hide-no-data
    return-object
    type="search"
    prepend-inner-icon="$search"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.enter.native.prevent.stop="doSearch"
    :menu-props="{
      closeOnContentClick: true,
      contentClass: 'searches-menu'
    }"
  >
    <template v-slot:item="{ item }">
      <div class="d-flex w-100 pr-0" :class="item.type">
        <v-list-item-content @click="doSearch(item)">
          <v-list-item-title>
            <text-highlight :queries="search ? search : '*'">
              {{ item.name }}
            </text-highlight>
          </v-list-item-title>
        </v-list-item-content>
      </div>
    </template>
    <!-- <template slot="prepend">
        <v-icon
          aria-label="Cerca"
          @click.prevent.stop="active = !active"
          color="black"
        >
          $search
        </v-icon>
      </template> -->
    <!-- <template v-if="$vuetify.breakpoint.mdAndUp" slot="append">
        <v-btn depressed height="48px" color="grey lighten-2">{{
          $t("navbar.search.searchBtn")
        }}</v-btn>
      </template> -->
  </v-combobox>
</template>
<style lang="scss">
.wp-search-input {
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;
  &.wp-search-input--closed {
    max-width: 45px;
    border: transparent;
  }
  border: 1px solid var(--v-grey-base);
  .v-input__control {
    display: inline;
    height: 100%;
    .v-input__slot {
      height: 100%;
      padding-right: 0px !important;
    }
  }
  .v-input__prepend-outer {
    align-self: center;
    margin-left: 10px;
    margin-right: 0px;
    padding-right: 4px;
  }
  .v-input__icon--append {
    display: none;
  }
  ::placeholder,
  .v-label {
    color: #828282 !important;
  }
}

.searches-menu {
  max-height: 80vh !important;
  background-color: blue;
  &.v-list--dense {
    .v-list-item {
      min-height: 32px;
    }
  }
  .v-list-item {
    .v-list-item__content {
      padding: 2px 0;
    }
    .v-list-item__title {
      font-size: 1rem;
    }
    .link {
      .v-list-item__title {
        color: var(--v-primary-base);
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .v-subheader {
    font: normal normal bold 1rem/3rem Muli;
    white-space: nowrap;
    &::before {
      content: "";
      display: block;
      width: 30px;
      height: 2px;
      background: var(--v-primary-base);
      margin-right: 8px;
    }
    &::after {
      content: "";
      display: block;
      width: 80%;
      height: 2px;
      background: var(--v-primary-base);
      margin-left: 8px;
    }
  }
}
</style>
<script>
import AnalyticsService from "~/service/analyticsService";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

import cmService from "@/commons/service/cmService";

export default {
  name: "WpSearchInput",
  data() {
    return {
      closed: true,
      loading: false,
      selected: null,
      search: null,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      }
    };
  },
  props: {
    closable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  methods: {
    onFocus() {
      this.closed = false;
      this.$emit("toggle", true);
    },
    onBlur() {
      this.closed = true;
      this.$emit("toggle", false);
    },
    fetchSuggestion: debounce(async function() {
      let _this = this;
      this.loading = true;
      if (this.search) {
        let newItems = [];
        cmService
          .search(this.search.trim(), ["post", "recipe", "page"], 1, 5)
          .then(function(response) {
            if (response.data.length > 0) {
              // newItems.push({ header: "News e ricette" });
              forEach(response.data, function(value) {
                newItems.push({
                  name: value.title,
                  type: "News e ricette",
                  ...value
                });
              });
              newItems.push({
                type: "link",
                name: "Cerca tra tutti i contenuti >"
              });
            }
          });
        _this.loading = false;
        _this.items = newItems;
      }
    }, 200),
    doSearch(item) {
      this.items = [];

      var currentQuery = {};
      currentQuery.q = this.search;

      if (currentQuery.q) {
        AnalyticsService.search(this.search);
        global.EventBus.$emit("resetFilters");
        if (item.type == "News e ricette") {
          let routeName;
          switch (item.subtype) {
            case "post":
              routeName = "NewsDetail";
              break;
            case "faq":
              routeName = "Faq";
              break;
            case "recipe":
              routeName = "RecipeDetail";
              break;
            case "page":
              routeName = "Page";
              break;
            default:
              routeName = "Page";
          }
          this.$router.push({
            name: routeName,
            params: { postName: item.slug }
          });
        } else {
          this.$router.push({
            name: "WpSearch",
            query: currentQuery
          });
        }

        this.$nextTick(() => {
          // this.$refs.autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    }
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
