<template functional>
  <span v-html="parent.$tc('miniCart.cartItemCount', props.count)"> </span>
</template>
<script>
export default {
  name: "CartItemCount",
  props: {
    count: { required: false, type: Number }
  }
};
</script>
