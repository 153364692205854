<template>
  <v-fab-transition>
    <v-btn
      class="scroll-on-top-btn"
      v-scroll="onScroll"
      v-show="fab"
      fab
      fixed
      bottom
      right
      depressed
      color="primary lighten-1"
      @click="toTop"
      height="48"
      width="48"
      :class="{
        'scroll-top-with-recaptcha': recaptchaTokenEnabled
      }"
    >
      <v-icon>$arrowUpward</v-icon>
    </v-btn>
  </v-fab-transition>
</template>
<style lang="scss">
.is-cordova {
  .scroll-on-top-btn {
    z-index: 11 !important;
    bottom: calc(
      #{$bottom-navigation-bar-height} * 3 + env(safe-area-inset-top) + 29px
    ) !important;
  }
}
.scroll-on-top-btn.v-btn--fixed.v-btn--bottom {
  bottom: 75px;
}
// .is-cordova
.is-cordova.keyboard-open {
  .scroll-on-top-btn {
    bottom: 20px !important;
  }
}
.is-cordova.keyboard-open.platform-ios {
  .scroll-on-top-btn {
    bottom: 35px !important;
  }
}
.is-cordova.platform-ios {
  .scroll-on-top-btn {
    bottom: 135px !important;
  }
}
</style>
<script>
export default {
  data() {
    return {
      fab: false
    };
  },
  computed: {
    recaptchaTokenEnabled() {
      return global.config.recaptchaTokenEnabled;
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 2000;
    },
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>
