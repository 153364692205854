var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list',{staticClass:"cart-item-list",class:{ 'checkout-item-list': _vm.isCheckout },attrs:{"id":"cartItemList","flat":"","subheader":""}},[[_c('v-subheader',{staticClass:"text-body-1 d-flex justify-space-between default--text"},[_c('CartItemCount',{attrs:{"count":_vm.cart.totalItems}}),_c('v-switch',{attrs:{"label":_vm.$t('miniCart.splitProductsOnCategory')},model:{value:(_vm.showSectors),callback:function ($$v) {_vm.showSectors=$$v},expression:"showSectors"}})],1)],(!_vm.showSectors)?_c('div',{staticClass:"no-sectors",class:[
        { 'cart-item-list-no-checkout': !_vm.isCheckout },
        { 'cart-item-list-no-checkout-higher': !_vm.isCheckout }
      ]},_vm._l((_vm.cart.cartItems),function(cartItem){return _c('div',{key:cartItem.cartItemId,staticClass:"align-center px-md-2"},[_c('CartItem',{attrs:{"item":cartItem,"isCheckout":_vm.isCheckout}})],1)}),0):_c('v-expansion-panels',{class:[
        { 'cart-item-list-no-checkout': !_vm.isCheckout },
        { 'cart-item-list-no-checkout-higher': !_vm.isCheckout }
      ],attrs:{"accordion":"","flat":"","multiple":"","tile":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.itemGroups),function([categoryId, group],index){return _c('v-expansion-panel',{key:categoryId},[_c('v-expansion-panel-header',{staticClass:"text-body-2 grey lighten-2 px-3",on:{"click":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.panel.includes(index) ? "$minus" : "$plus")+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(_vm._s(group.name))]),_c('span',[_vm._v(" "+_vm._s(`${_vm.$tc("lists.products", group.products.length)}`)+" ")])])]),_c('v-expansion-panel-content',[_c('transition-group',{attrs:{"name":"bounce"}},_vm._l((group.products),function(item){return _c('CartItem',{key:item.itemId,staticClass:"sectorItem",attrs:{"item":item,"isCheckout":_vm.isCheckout}})}),1)],1)],1)}),1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }