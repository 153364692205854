<template>
  <div class="cart-item">
    <v-list-item
      :to="
        !isProductGift
          ? {
              name: 'Product',
              params: { slug: item.product.slug }
            }
          : ''
      "
      :class="isCheckout ? 'py-3 is-checkout' : 'py-2 is-not-checkout'"
    >
      <v-row class="w-100 my-2 my-sm-0" align="center" justify="space-between">
        <v-col cols="12" :sm="isCheckout ? 6 : 6" class="pa-0">
          <div class="d-flex">
            <v-list-item-avatar
              width="70"
              height="70"
              rouned="0"
              class="my-0 rounded-0"
            >
              <img
                :src="item.product.mediaURL"
                :alt="item.product.name"
                onerror="this.onerror=null;this.src='/no-icon.png'"
              />
            </v-list-item-avatar>

            <v-list-item-content class="default--text pa-0 pa-sm-2">
              <div class="font-weight-bold text-body-2">
                {{ item.product.name }}
              </div>
              <v-list-item-subtitle class="text-uppercase text-body-2">
                {{ item.product.shortDescr }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="item.cartItemInfo.size"
                class="cart-item-info"
              >
                Preferenza: {{ item.cartItemInfo.size }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-if="
                  itemAdjustment &&
                    itemAdjustment.userGiftCertificateId &&
                    itemAdjustment.giftCertificate
                "
                class="cart-item-info promo--text"
              >
                <em>{{ itemAdjustment.giftCertificate.name }}: </em>
                <!-- -{{ $n(itemAdjustment.valueVariation, "currency") }} -->
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-if="
                $vuetify.breakpoint.xsOnly &&
                  !(
                    item.product.productInfos &&
                    item.product.productInfos.USER_DISABLED == '1'
                  )
              "
            >
              <v-btn
                outlined
                color="primary"
                aria-label="Rimuovi dal carrello"
                @click.prevent="remove"
                @mousedown.stop
              >
                <v-icon color="primary">$delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </div>
        </v-col>

        <v-col cols="12" :sm="isCheckout ? 6 : 6" class="pa-0">
          <v-list-item-action class="mr-0 w-100 mt-3 mt-sm-0 mb-0">
            <div
              v-if="item.editable"
              class="d-flex align-center w-100"
              :class="
                isCheckout || $vuetify.breakpoint.xs
                  ? 'justify-space-between'
                  : 'justify-end'
              "
            >
              <ProductPrice
                v-if="isCheckout"
                :product="item.product"
                :isList="true"
              ></ProductPrice>
              <ProductQty :item="item" :product="item.product" />

              <div class="price font-weight-bold">
                {{ $n(item.grossTotal, "currency") }}
              </div>
              <v-btn
                outlined
                aria-label="Rimuovi dal carrello"
                @click.prevent="remove"
                @mousedown.stop
                color="primary"
                v-if="
                  $vuetify.breakpoint.smAndUp &&
                    !(
                      item.product.productInfos &&
                      item.product.productInfos.USER_DISABLED == '1'
                    )
                "
              >
                <v-icon>$delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-col>
      </v-row>
    </v-list-item>
    <v-checkbox
      v-if="isCheckout"
      dense
      color="primary"
      hide-details
      class="accept-alternatives my-1 pt-0"
      v-model="acceptAlternative"
    >
      <template v-slot:label>
        <span>{{ $t("cartDetail.acceptAlternatives") }}</span>
      </template>
    </v-checkbox>

    <v-textarea
      v-if="isCheckout"
      ref="note"
      :label="$t('cartDetail.note')"
      auto-grow
      rows="1"
      row-height="15"
      prepend-inner-icon="$edit"
      v-model="userNotes"
      :rules="notesRule"
      @blur="updateNote(item, 'user_notes', tempUserNotes)"
    ></v-textarea>
    <v-divider v-else></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <!-- <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div> -->
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn outlined text @click="removeDialog = false">
            No
          </v-btn>

          <v-btn
            class="primary white--text"
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.cart-item {
  .price {
    min-width: 75px;
    text-align: center;
  }
  .is-not-checkout {
    .price {
      font-size: 18px;
    }
  }
  .qty-wrap {
    width: 100%;
    min-width: 130px;
  }

  .product-price {
    text-align: center;
    @media (max-width: 320px) {
      min-width: auto;
    }
  }
  .gross-total {
    width: auto !important;
    min-width: 70px;
    text-align: right;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto !important;
      width: 25%;
      font-size: 18px !important;
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductQty from "@/components/product/ProductQty.vue";
import productMixin from "~/mixins/product";

import { isOnlyCharacterAndNumber } from "@/baskoValidationRules";

import { mapActions, mapGetters } from "vuex";

import get from "lodash/get";

export default {
  name: "CartItem",
  components: {
    ProductPrice,
    ProductQty
  },
  props: {
    isCheckout: { type: Boolean, default: false },
    item: { type: Object, required: true }
  },
  mixins: [productMixin],
  data() {
    return {
      removeDialog: false,
      tempUserNotes: "",
      notesRule: [isOnlyCharacterAndNumber()]
    };
  },
  computed: {
    product() {
      return this.item.product;
    },
    isProductGift() {
      return (
        this.item && this.item.product.productInfos.PRODUCT_NATURE_ID === "8"
      );
    },
    classCardContainerObject: function() {
      if (this.isCheckout && this.$vuetify.breakpoint.mdAndUp) {
        return "isCheckout d-flex flex-column justify-space-around";
      } else {
        if (!this.$vuetify.breakpoint.xs) {
          return "d-flex flex-row justify-space-around";
        }
        return "XS";
      }
    },
    acceptAlternative: {
      get() {
        return (
          get(this.item, "cartItemInfo.accept_alternatives", "true") == "true"
        );
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    // userNotes: {
    //   get() {
    //     return get(this.item, "cartItemInfo.user_notes", "");
    //   },
    //   set(value) {
    //     this.setCartItemInfo({
    //       items: this.item,
    //       name: "user_notes",
    //       value: "" + value
    //     });
    //   }
    // },
    userNotes: {
      get() {
        return this.item.cartItemInfo.user_notes;
      },
      set(value) {
        this.tempUserNotes = value;
      }
    },
    itemAdjustment() {
      return this.getOrderAdjustmentByItemId(this.item.itemId);
    },
    ...mapGetters({
      getOrderAdjustmentByItemId: "cart/getOrderAdjustmentByItemId"
    })
  },
  methods: {
    ...mapActions({ setCartItemInfo: "cart/setCartItemInfo" }),
    updateNote(item, name, tempUserNotes) {
      if (this.$refs.note.validate()) {
        this.setCartItemInfo({
          items: item,
          name: name,
          value: tempUserNotes
        });
      } else {
        this.$refs.note.internalValue = "";
      }
    },
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    }
  }
};
</script>
