let routes = [
  {
    path: "/eugenio",
    meta: { requiresAuth: true, breadCrumb: "Eugenio" },
    component: () => import("@/views/profile/Eugenio.vue"),

    /*
    eugenio reserved-area-eugenio

    -> /eugenio-home
    -> /basko-box-plan
    -> /basko-box-questions/:isRegistration
    -> /basko-box-payment-method
    -> /basko-box-confirm-consent/:timeslotId/:weekDay/:addressId
    -> /basko-box-address
    -> /basko-box-timeslot/:addressId
    -> /basko-box-order/:boxId
    -> /basko-box-thank-you
    */
    children: [
      {
        path: "eugenio-home",
        name: "EugenioHome",
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio euGenio"
        },
        component: () => import("@/views/eugenio/EugenioHome.vue")
      },
      {
        path: "basko-box-timeslot",
        name: "BaskoBoxTimeslot",
        props: route => ({
          addressId: route.query.addressId
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Seleziona fascia oraria"
        },
        component: () => import("@/views/eugenio/BaskoBoxTimeslot.vue")
      },
      {
        path: "basko-box-confirm-consent",
        name: "BaskoBoxConfirmConsent",
        props: route => ({
          addressId: route.query.addressId,
          timeslotId: route.query.timeslotId,
          weekDay: route.query.weekDay
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Conferma i tuoi consensi"
        },
        component: () => import("@/views/eugenio/BaskoBoxConfirmConsent.vue")
      },
      {
        path: "basko-box-questions",
        name: "BaskoBoxQuestions",
        props: route => ({
          isRegistration: route.query.isRegistration
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Conosciamoci meglio"
        },
        component: () => import("@/views/eugenio/BaskoBoxQuestions.vue")
      },
      {
        path: "basko-box-payment-method",
        name: "BaskoBoxPaymentMethod",
        meta: {
          requiresAuth: true,
          breadCrumb: "Inserisci metodo di pagamento"
        },
        component: () => import("@/views/eugenio/BaskoBoxPaymentMethod.vue")
      },
      {
        path: "basko-box-thank-you",
        name: "BaskoBoxThankYou",
        meta: {
          requiresAuth: true,
          breadCrumb: "Configurazione completata"
        },
        component: () => import("@/views/eugenio/BaskoBoxThankYou.vue")
      }
    ]
  }
];

export default routes;
