<template>
  <v-app-bar
    app
    ref="navbar"
    class="appbar"
    clipped-left
    v-scroll="onScroll"
    v-resize="onResize"
    :height="isCordova ? navbarHeightExpanded : calculateNavbardHeight"
  >
    <!-- top menu -->
    <NavbarSiteSelection :applicationConfig="applicationConfig" />
    <!-- navbar row -->
    <NavbarSite v-if="site == 'site'" :siteLinks="siteLinks" />
    <NavbarShop v-else />

    <v-progress-linear
      arial-label="Caricamento in corso"
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style lang="scss">
.v-app-bar.appbar.v-app-bar--fixed {
  background-color: $white !important;
  z-index: 11;
  .v-toolbar__content {
    display: block;
    padding: 0px;
    transition: height 0.2s ease;
    -webkit-transition: height 0.2s ease;
  }
  .navbar-line {
    padding-top: 15px;
    padding-bottom: 15px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      &.navbar-site {
        padding-top: 0px;
        padding-bottom: 0px;
        height: 80px;
        .navbar-row {
          height: 100%;
        }
      }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0px;
      margin-top: 12px;
    }
  }
  .username {
    line-height: 1.2;
    text-align: left;
  }

  .menu-btn {
    .open-menu-btn {
      .v-btn__content {
        .v-icon {
          margin-bottom: 3px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        font-size: 10px;
      }
    }
  }
}
</style>
<script>
import NavbarSiteSelection from "@/components/navigation/NavbarSiteSelection.vue";
import NavbarSite from "@/components/navigation/NavbarSite.vue";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Navbar",
  mixins: [login, cartInfo],
  components: {
    NavbarSiteSelection,
    NavbarSite,
    NavbarShop: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/navigation/NavbarShopMobile.vue")
        : import("@/components/navigation/NavbarShop.vue")
  },
  props: {
    applicationConfig: { type: Object, required: false },
    siteLinks: { type: Array, default: () => [] }
  },
  data() {
    return {
      navbarHeightExpanded: 136,
      navbarHeightSmall: 80,
      navbarHeightMobileExpanded: 181,
      navbarHeightMobileSmall: 141,
      calculateNavbardHeight: 0
    };
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      site: "custom/getSite"
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft"
    }),
    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "HomeSite",
            path: "/"
          });
        }, 200);
      }
    },
    toggleShowSearch() {},
    onScroll() {
      if (window.scrollY > 55 && !document.body.classList.contains("small")) {
        this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
          ? this.navbarHeightMobileSmall
          : this.navbarHeightSmall;
        document.body.classList.add("small");
      } else if (
        window.scrollY < 55 &&
        document.body.classList.contains("small")
      ) {
        this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
          ? this.navbarHeightMobileExpanded
          : this.navbarHeightExpanded;
        document.body.classList.remove("small");
      }
    },
    onResize() {
      let smAndDown = this.$vuetify.breakpoint.smAndDown;

      if (smAndDown && !document.body.classList.contains("sm-and-down")) {
        this.calculateNavbardHeight = document.body.classList.contains("small")
          ? this.navbarHeightMobileSmall
          : this.navbarHeightMobileExpanded;
        document.body.classList.add("sm-and-down");
      } else if (
        !smAndDown &&
        document.body.classList.contains("sm-and-down")
      ) {
        this.calculateNavbardHeight = document.body.classList.contains("small")
          ? this.navbarHeightSmall
          : this.navbarHeightExpanded;
        document.body.classList.remove("sm-and-down");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  mounted() {
    this.onResize();
    this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
      ? this.navbarHeightMobileExpanded
      : this.navbarHeightExpanded;
  }
};
</script>
